import React from 'react'

import { CurrencyIcon } from '../CurrencyIcon'
import { CurrencyBadgeWrapper } from './CurrencyBadge.styled'

import CheckMark from '../../images/icons/password-terms-check.svg?react'

interface CurrencyBadgeProps {
  currencyCode: string
  isSelected: boolean
  onClick: (e: React.SyntheticEvent) => void
}

export const CurrencyBadge = ({ currencyCode, isSelected, onClick }: CurrencyBadgeProps) => (
  <CurrencyBadgeWrapper isSelected={isSelected} onClick={onClick}>
    <CurrencyIcon className='currency-icon' currency={currencyCode} size='2.2rem' />
    <span data-test='currency' className='currency-name'>
      {currencyCode}
    </span>
    {isSelected && <CheckMark />}
  </CurrencyBadgeWrapper>
)
