import { getCurrencyImageUrl } from 'mmfintech-commons'

import { CurrencyIconWrapper } from './CurrencyIcon.styled'
import { configuration } from 'mmfintech-backend-api'

interface CurrencyIconProps {
  currency: string
  size?: string
  className?: string
}

export const CurrencyIcon = ({ currency, size = '3rem', className }: CurrencyIconProps) => (
  <CurrencyIconWrapper size={size} className={className}>
    <img src={`${configuration.getBaseUrl()}${getCurrencyImageUrl(currency, 'svg')}`} alt='' />
  </CurrencyIconWrapper>
)
