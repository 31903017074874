import styled from 'styled-components'

export const CurrencyIconWrapper = styled.span<{ size: string }>`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;

  &.card,
  &.bordered {
    border: 1px solid #ddd;
  }

  & > img {
    border-radius: 50%;
    width: 100%;
  }
`
