import { tr } from 'mmfintech-commons'

import { CurrencyIcon } from '../../../components'
import { AccountOverviewRowWrapper } from './AccountsOverview.styled'

import { AccountBalanceOut } from 'mmfintech-commons-types'

type AccountOverviewRowProps = {
  account: AccountBalanceOut & { permission?: string }
}

export const AccountOverviewRow = ({ account }: AccountOverviewRowProps) => {
  const { currencyCode, balance, name, permission } = account

  return (
    <AccountOverviewRowWrapper>
      <div className='cell'>
        <CurrencyIcon currency={currencyCode} size='3.4rem' className='card mr-1 currency-icon' />
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.COLUMNS.CURRENCY', 'Currency')}</span>
        <span>{currencyCode}</span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.COLUMNS.NAME', 'Account name')}</span>
        <span>{name}</span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.COLUMNS.PERMISSIONS', 'Permissions')}</span>
        <span>
          {permission === 'WRITER'
            ? tr('FRONTEND.ACCOUNTS.ACCESS.FULL', 'Full')
            : tr('FRONTEND.ACCOUNTS.ACCESS.VIEW_ONLY', 'View only')}
        </span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.COLUMNS.BALANCE', 'Balance')}</span>
        <span>{balance}</span>
      </div>
    </AccountOverviewRowWrapper>
  )
}
